import DrawerCard from "../../drawer-card";
import { ReactComponent as CloseIcon } from "../../../libs/assets/icons/close-icon.svg";
import { ReactComponent as BackIcon } from "../../../libs/assets/icons/chevron-left-icon.svg";
import { ReactComponent as GiveIcon } from "../../../libs/assets/icons/give-gift-icon.svg";
import { ReactComponent as PixIcon } from "../../../libs/assets/icons/pix-icon.svg";

import styles from "./styles.module.css";
import classnames from "classnames";
import { useContext } from "react";
import { DrawerContext } from "../../../contexts/drawer-context";
import PaymentCard from "../payment-card";
import Input from "../../input";
import useGoOutEffectHook from "../../../hooks/useGoOutEffect";

type ExtendedDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ExtendedDrawer = ({ isOpen, onClose }: ExtendedDrawerProps) => {
  const context = useContext(DrawerContext);

  if (!context) {
    throw new Error("MyContext.Provider is missing");
  }

  const {
    giftList: itens,
    removeGift,
    currentStep,
    goPrevStep,
    choosePaymentMethod,
    data,
    handleChangeGifterDataValues,
    paymentMethod,
    dataError,
  } = context;

  const renderGifts = () => (
    <ul className={styles.giftList}>
      {itens.map(({ id, photo_src, name, cost }) => (
        <li key={`extended-${id}`}>
          <DrawerCard
            src={photo_src}
            value={cost}
            title={name}
            onRemove={() => removeGift(id)}
          />
        </li>
      ))}
    </ul>
  );

  const steps = {
    list_itens: {
      title: "Caixa de presentes",
      render: renderGifts,
    },
    payment_method: {
      title: "Selecione como deseja entregar o presente",
      render: () => (
        <div className={styles.paymentMethods}>
          <PaymentCard
            name="give"
            Icon={GiveIcon}
            label="Entregar presente pessoalmente"
            onClick={choosePaymentMethod}
            isSelected={paymentMethod === "give"}
          />
          <PaymentCard
            name="pix"
            Icon={PixIcon}
            label="Fazer um Pix no valor do presente"
            onClick={choosePaymentMethod}
            isSelected={paymentMethod === "pix"}
          />
        </div>
      ),
    },
    gifter_data: {
      title: "Insira seus dados para reservar o presente selecionado",
      render: () => <></>,
    },
    review: {
      title: "Revise os presentes que deseja reservar",
      render: renderGifts,
    },
  };

  const { render: RenderStep, title } = steps[currentStep];
  const [isVisible, isGoingOut, triggerGoOutEffect] = useGoOutEffectHook(
    isOpen,
    400
  );

  const handleClose = () => {
    triggerGoOutEffect();
    onClose();
  };

  return (
    <>
      {isVisible && (
        <div
          className={classnames(styles.extendedDrawer, {
            [styles.showDrawer]: isOpen,
            [styles.hideDrawer]: isGoingOut,
          })}
        >
          <div className={styles.extendedHeader}>
            <div>
              {currentStep !== "list_itens" && (
                <BackIcon onClick={goPrevStep} />
              )}
              <span>{title}</span>
            </div>
            <CloseIcon onClick={handleClose} />
          </div>
          <div className={styles.content}>
            {currentStep !== "gifter_data" && <RenderStep />}
            {currentStep === "gifter_data" && (
              <div className={styles.gifterDataContainer}>
                <Input
                  label="Nome completo"
                  name="name"
                  value={data.name}
                  onChange={handleChangeGifterDataValues}
                  error={dataError?.name}
                />
                <Input
                  label="CPF"
                  name="cpf"
                  value={data.cpf}
                  onChange={handleChangeGifterDataValues}
                  error={dataError?.cpf}
                  mask="999.999.999-99"
                />
                <Input
                  label="Telefone (Whatsapp)"
                  name="phone"
                  value={data.phone}
                  onChange={handleChangeGifterDataValues}
                  placeholder="Ex: 84 9 12345678"
                  error={dataError?.phone}
                  mask="(99) 9 9999-9999"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ExtendedDrawer;
