import { createContext, useState, useMemo, useCallback } from "react";

import {
  DrawerContextType,
  GifterDataType,
  ProviderProps,
  StepType,
} from "./drawer-context-constants";

const DrawerContext = createContext<DrawerContextType | undefined>(undefined);

const DrawerProvider = ({
  children,
  giftList,
  setGiftList,
  refetchList,
}: ProviderProps) => {
  const [steps, setSteps] = useState<Array<StepType>>(["list_itens"]);
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [data, setData] = useState<GifterDataType>({
    name: undefined,
    cpf: undefined,
    phone: undefined,
  });
  const [dataError, setDataError] = useState<any>({});
  const [showConfirmationModal, setShowConfirmationModal] = useState<
    null | "pix-modal" | "success-modal"
  >(null);

  const removeGift = useCallback(
    (id: number) => {
      const list = giftList.filter((gift) => gift.id !== id);
      setGiftList(list);
    },
    [giftList, setGiftList]
  );

  const currentStep = useMemo(() => steps[steps.length - 1], [steps]);

  const goPrevStep = useCallback(() => {
    const list = steps.filter(
      (__, index) => steps.length > 0 && index !== steps.length - 1
    );

    setSteps(list);
  }, [steps]);

  const choosePaymentMethod = useCallback((type: string) => {
    setPaymentMethod(type);
  }, []);

  const isKeyOfGifterData = useCallback(
    (key: string): key is keyof GifterDataType => {
      return key in data;
    },
    [data]
  );

  const hasErrorOnGiftData = useCallback(() => {
    let error = {};

    Object.keys(data).forEach((key: string) => {
      if (isKeyOfGifterData(key)) {
        if (!data[key]) error = { ...error, [key]: "Campo obrigatório" };
      }
    });

    setDataError(error);

    return error;
  }, [data, isKeyOfGifterData]);

  const canGoToNextStep = useMemo(() => {
    if (currentStep === "payment_method") {
      return !!paymentMethod;
    }

    return true;
  }, [currentStep, paymentMethod]);

  const goNextStep = useCallback(() => {
    const STEP_LIST: Array<StepType> = [
      "list_itens",
      "payment_method",
      "gifter_data",
      "review",
    ];

    if (!steps.includes("review")) {
      const nextStep = STEP_LIST.find((step) => !steps.includes(step));

      setSteps((prev) => {
        if (nextStep) return [...prev, nextStep];
        return prev;
      });
    } else {
      // On last step will make a post and reset context states:
      if (paymentMethod === "pix") {
        setShowConfirmationModal("pix-modal");
      } else if (paymentMethod === "give") {
        setShowConfirmationModal("success-modal");
      }
    }
  }, [steps, paymentMethod]);

  const handleChangeGifterDataValues = useCallback(
    (name: string, value: string) => {
      setData((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    []
  );

  const formattedGiftsData = useMemo(() => {
    const formattedData = {
      giftIds: giftList.map((gift) => String(gift.id)),
      guest_name: data.name || "",
      guest_cpf: data.cpf || "",
      guest_phone: data.phone || "",
      payment_method: paymentMethod,
    };

    return formattedData;
  }, [giftList, data, paymentMethod]);

  const resetContext = useCallback(() => {
    setData({
      name: undefined,
      cpf: undefined,
      phone: undefined,
    });
    setGiftList([]);
    setShowConfirmationModal(null);
    setSteps(["list_itens"]);
    setPaymentMethod("");
  }, [setGiftList]);

  const onCloseConfirmationModal = useCallback(() => {
    resetContext();
  }, [resetContext]);

  const contextValue = useMemo(
    () => ({
      removeGift,
      giftList,
      currentStep,
      goPrevStep,
      goNextStep,
      canGoToNextStep,
      choosePaymentMethod,
      data,
      handleChangeGifterDataValues,
      paymentMethod,
      hasErrorOnGiftData,
      showConfirmationModal,
      onCloseConfirmationModal,
      formattedGiftsData,
      refetchList,
      dataError,
    }),
    [
      removeGift,
      giftList,
      currentStep,
      goPrevStep,
      goNextStep,
      canGoToNextStep,
      choosePaymentMethod,
      data,
      handleChangeGifterDataValues,
      paymentMethod,
      hasErrorOnGiftData,
      showConfirmationModal,
      onCloseConfirmationModal,
      formattedGiftsData,
      refetchList,
      dataError,
    ]
  );

  return (
    <DrawerContext.Provider value={contextValue}>
      {children}
    </DrawerContext.Provider>
  );
};

export { DrawerProvider, DrawerContext };
