import styles from "./styles.module.css";
import { ReactComponent as CopyIcon } from "../../libs/assets/icons/copy-icon.svg";
import { ReactComponent as CheckIcon } from "../../libs/assets/icons/check-snackbar-icon.svg";
import { useState } from "react";
import Snackbar, { SNACKBAR_THEMES } from "../snackbar";

type CopiableIputProps = {
  content: string;
  placeholder?: string;
};

const CopiableInput = ({ content, placeholder }: CopiableIputProps) => {
  const [wasCopied, setWasCopied] = useState(false);

  const onCopy = () => {
    navigator.clipboard.writeText(content).then(() => {
      setWasCopied(true);
    });
  };

  return (
    <div className={styles.inputContainer}>
      {wasCopied && (
        <Snackbar
          theme={SNACKBAR_THEMES.SUCCESS}
          Icon={CheckIcon}
          label="Código Pix copiado com sucesso"
          onClose={() => {
            setWasCopied(false);
          }}
        />
      )}
      <div className={styles.inputWrapper}>
        <input type="text" value={content} readOnly />
        <CopyIcon onClick={onCopy} />
      </div>
      <span>{placeholder}</span>
    </div>
  );
};

export default CopiableInput;
