import { post } from './utils'

export type FormatedGuestData = {
    name: string,
    adults_number: number,
    kids_number: number,
    message?: string,
    phone?: string,
}

export const confirmGuest = async (data: FormatedGuestData) => {
    const URL = 'guests/create/'

    const res = await post({url: URL, data})

    return res
}