import Button from "../button";
import styles from "./styles.module.css";

type DrawerCardProps = {
  src: string;
  value: string | number;
  title: string;
  onRemove: () => void;
};

const DrawerCard = ({ src, value, title, onRemove }: DrawerCardProps) => {
  return (
    <div className={styles.cardWrapper}>
      <img src={src} alt={title} />
      <div>
        <h3>{title}</h3>
        <span>R$ {value}</span>
        <Button label="Remover" onClick={onRemove} theme="secondary" />
      </div>
    </div>
  );
};

export default DrawerCard;
