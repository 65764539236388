import styles from "./styles.module.css";
import { ReactComponent as CloseBuIcon } from "../../../libs/assets/icons/close-icon.svg";
import { ReactComponent as SuccessImage } from "../../../libs/assets/images/gift-sent-image.svg";
import { ReactComponent as CheckIcon } from "../../../libs/assets/icons/check-snackbar-icon.svg";
import Button from "../../button";
import CopiableInput from "../../copiable-input";
import { useState } from "react";
import Snackbar, { SNACKBAR_THEMES } from "../../snackbar";

type ConfirmationModalProps = {
  isOpen: boolean;
  type: null | "success-modal" | "pix-modal";
  total?: number | string;
  onClose: () => void;
};

const ConfirmationModal = ({
  isOpen,
  type,
  total,
  onClose,
}: ConfirmationModalProps) => {
  const pixKey =
    process.env.REACT_APP_PIX_CONTENT ??
    "00020126330014BR.GOV.BCB.PIX0111116005754045204000053039865802BR5925Maria Gabriella Pinheiro 6009SAO PAULO62140510uXtvqSgOxr6304B6A6";
  const [wasCopied, setWasCopied] = useState(false);

  const onCopy = () => {
    navigator.clipboard.writeText(pixKey).then(() => {
      setWasCopied(true);
    });
  };

  const modal = {
    "success-modal": {
      title: "Presente reservado com sucesso",
      content: (
        <>
          <SuccessImage />
          <h4>
            Entregue o presente no dia do casamento ou converse com os noivos
            para combinar a entrega/tirar dúvidas.
          </h4>
          <div>
            <span>Whatsapp da noiva: (84) 9934-8457</span>
            <span>Whatsapp do noivo: (84) 8191-0972</span>
          </div>
          <span>
            OBS: Caso desista deste presente, por favor cancele a reserva para
            que outros saibam que o presente está disponível.
          </span>
          <Button label="Entendi" onClick={onClose} />
        </>
      ),
    },
    "pix-modal": {
      title: "Envie seu presente para Maria e Miguel",
      content: (
        <>
          <div className={styles.pixWrapper}>
            <h4 className={styles.pixContent}>
              Abra o aplicativo do seu banco, selecione a opção de PIX Copia e
              Cola e cole o código abaixo.
            </h4>
            <h5>
              Valor: <b>R$ {total}</b>
            </h5>
            <CopiableInput content={pixKey} />
            <Button label="Copiar código" onClick={onCopy} />
            <h6>
              Pague até o dia: <b>09 de Novembro, 2024</b>
            </h6>
          </div>
          {wasCopied && (
            <Snackbar
              theme={SNACKBAR_THEMES.SUCCESS}
              Icon={CheckIcon}
              label="Código Pix copiado com sucesso"
              onClose={() => {
                setWasCopied(false);
              }}
            />
          )}
        </>
      ),
    },
  };

  return (
    <>
      {isOpen && type && (
        <>
          <div className={styles.modalOverlay}></div>
          <div className={styles.modalWrapper}>
            <div className={styles.headerModal}>
              <h2>{modal[type].title}</h2>
              <CloseBuIcon onClick={onClose} />
            </div>
            <div className={styles.content}>{modal[type].content}</div>
          </div>
        </>
      )}
    </>
  );
};

export default ConfirmationModal;
