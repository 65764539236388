import { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { Howl, Howler } from "howler";

import useDeviceDetect from "../../hooks/useDeviceDetect";
import usePrevious from "../../hooks/usePreveous";
import useGoOutEffectHook from "../../hooks/useGoOutEffect";

import { ReactComponent as Logo } from "../../libs/assets/logo/header-logo.svg";
import { ReactComponent as PlayIcon } from "../../libs/assets/icons/play-icon.svg";
import { ReactComponent as PauseIcon } from "../../libs/assets/icons/pause-icon.svg";
import yellowSong from "../../libs/assets/audio/yellow.mp3";

import styles from "./styles.module.css";
import { HEADER_ITENS } from "./constants";

const Header = () => {
  const [scrollPos, setScrollPos] = useState<number>(0);
  const [isOpen, setIsOpen] = useState(false);
  const wasOpen = usePrevious(isOpen);
  const home = document.querySelector("#home")?.clientHeight;
  const { isMobile } = useDeviceDetect();
  const [isVisible, isGoingOut, triggerGoOutEffect] = useGoOutEffectHook(
    isOpen,
    400
  );
  const [isPlayingSong, setIsPlayingSong] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  /* const sound = new Howl({
    src: [yellowSong],
    volume: 0.5,
    autoplay: false,
    loop: true,
  }); */

  const toggleMusic = () => {
    console.log(!isPlayingSong);
    console.debug(isPlayingSong);
    if (audioRef.current) {
      if (!isPlayingSong) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
      setIsPlayingSong((prev) => !prev);
    }
  };

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPos(position);
  };

  const onToggleMenu = () => {
    setIsOpen((prev) => {
      if (wasOpen && prev) triggerGoOutEffect();
      return !prev;
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header
        className={classnames(styles.headerBg, {
          [styles.fixed]: home && scrollPos >= home && !isMobile,
        })}
      >
        {isMobile ? (
          <>
            <div className={styles.mobileHeader}>
              <button
                type="button"
                onClick={onToggleMenu}
                className={classnames(styles.sandwichMenu, {
                  [styles.openMenu]: isOpen,
                  [styles.closeMenu]: !isOpen && wasOpen,
                })}
              >
                <div className={styles.topBar}></div>
                <div className={styles.centerBar}></div>
                <div className={styles.bottomBar}></div>
              </button>
              <a href="#home">
                <Logo />
              </a>
              <button
                onClick={toggleMusic}
                type="button"
                className={styles.toggleSong}
              >
                {!isPlayingSong ? <PlayIcon /> : <PauseIcon />}
              </button>

              <audio
                style={{ display: "none" }}
                ref={audioRef}
                src={yellowSong}
                loop={false}
              >
                <track
                  default
                  kind="captions"
                  srcLang="pt-br"
                  src={yellowSong}
                />
              </audio>
            </div>
            {isVisible && (
              <ul
                className={classnames({
                  [styles.showMenu]: isVisible,
                  [styles.hideMenu]: isGoingOut,
                })}
              >
                {HEADER_ITENS.filter((item) => item.label).map((item) => (
                  <a
                    onClick={onToggleMenu}
                    href={item.href}
                    key={"key-logo" + item.label}
                  >
                    <li>{item.label}</li>
                  </a>
                ))}
              </ul>
            )}
          </>
        ) : (
          <nav>
            <ul className={styles.headerWrapper}>
              {HEADER_ITENS.map((item) => {
                if (item.logo)
                  return (
                    <a href={item.href} key={"key-logo" + item.label}>
                      <li>
                        <item.logo />
                      </li>
                    </a>
                  );

                return (
                  <a key={"key-" + item.href} href={item.href}>
                    <li>{item.label}</li>
                  </a>
                );
              })}
            </ul>
          </nav>
        )}
      </header>
      {home && scrollPos >= home && <div className={styles.spacing}></div>}
    </>
  );
};

export default Header;
