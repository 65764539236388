import {get, post} from './utils'

export const getAllGifts = async (filters: Array<string> | null = [], computer: string = '') => {
    let GIFTS_URL = 'gifts/'

    if(filters?.length) {
        GIFTS_URL = GIFTS_URL + `?filters=${filters?.join(',')}`
    }

    if(computer) GIFTS_URL = GIFTS_URL + `${filters?.length ? '&' : '?'}computer=${computer}`

    const res = await get({url: GIFTS_URL})

    return res.response
}

export type FormatedGiftData = {
    giftIds: Array<string>
    guest_name: string
    guest_cpf: string,
    guest_phone: string,
    payment_method: string
}

export const chooseGifts = async (data: FormatedGiftData) => {
    const URL = 'gifts/chooseGift/'

    const res = await post({url: URL, data})

    return res
}