import classnames from "classnames";
import styles from "./styles.module.css";

type FilterTagProps = {
  label: string;
  name: string;
  checked: boolean;
  onSelectTag: (name: any) => void;
};

const FilterTag = ({ label, name, checked, onSelectTag }: FilterTagProps) => {
  const onSelect = (e: React.MouseEvent<HTMLElement>) => {
    const tagTarget = e.target as HTMLInputElement;
    const { name: targetName } = tagTarget;
    onSelectTag(targetName);
  };

  return (
    <div
      className={classnames(styles.filterTag, { [styles.checked]: checked })}
    >
      <label htmlFor={name}>{label}</label>
      <input
        type="checkbox"
        name={name}
        id={name}
        onClick={onSelect}
        checked={checked}
      />
    </div>
  );
};

export default FilterTag;
