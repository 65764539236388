import classnames from "classnames";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";

interface ButtonProps {
  label: string;
  theme?: "primary" | "outlined" | "link" | "secondary";
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  type?: "button" | "submit" | "reset";
  value?: string;
  name?: string;
  disabled?: boolean;
  href?: string;
}

const Button = ({
  label,
  onClick,
  theme = "primary",
  type = "button",
  value,
  name,
  disabled = false,
  href = "/",
}: ButtonProps) => {
  return href && theme === "link" ? (
    <Link
      className={classnames(styles.button, styles.link)}
      to={href}
      target="_blank"
    >
      {label}
    </Link>
  ) : (
    <button
      className={classnames(styles.button, styles[theme])}
      type={type}
      onClick={onClick}
      value={value}
      name={name}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button;
