import classnames from "classnames";
import styles from "./styles.module.css";
import {
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  parseISO,
} from "date-fns";
import { useEffect, useState } from "react";

type TimerProps = {
  date: {
    day: string | number;
    month: string | number;
    year: string | number;
  };
};

type TimerState = {
  days: null | number | string;
  hours: null | number | string;
  minutes: null | number | string;
};

const getTimer = (goalSTR: string) => {
  const goal = parseISO(goalSTR);
  const now = new Date().toISOString();
  const remainingDays =
    differenceInCalendarDays(goal, now) <= 0
      ? 0
      : differenceInCalendarDays(goal, now);
  const remainingHours =
    differenceInHours(goal, now) - (remainingDays - 1) * 24 <= 0
      ? 0
      : differenceInHours(goal, now) - (remainingDays - 1) * 24;
  const remainingMinutes =
    differenceInMinutes(goal, now) -
      ((remainingDays - 1) * 24 + remainingHours) * 60 <=
    0
      ? 0
      : differenceInMinutes(goal, now) -
        ((remainingDays - 1) * 24 + remainingHours) * 60;

  return { remainingDays, remainingHours, remainingMinutes };
};

const Timer = ({ date }: TimerProps) => {
  const { day, month, year } = date;
  const goal = new Date(`${year}-${month}-${day}T00:00:00Z`).toISOString();

  const [timer, setTimer] = useState<TimerState>({
    days: getTimer(goal).remainingDays,
    hours: getTimer(goal).remainingHours,
    minutes: getTimer(goal).remainingMinutes,
  });

  useEffect(() => {
    if (goal) {
      const intervalId = setInterval(() => {
        const { remainingDays, remainingHours, remainingMinutes } =
          getTimer(goal);
        setTimer({
          days: remainingDays,
          hours: remainingHours,
          minutes: remainingMinutes,
        });
      }, 30000);

      return () => clearInterval(intervalId);
    }
  }, [goal]);

  return (
    <div className={styles.timerWrapper}>
      <div className={classnames(styles.timerContainer, styles.days)}>
        <h3>{timer?.days}</h3>
        <span>Dias</span>
      </div>
      <div className={styles.timerContainer}>
        <h3>{timer?.hours}</h3>
        <span>Horas</span>
      </div>
      <div className={classnames(styles.timerContainer, styles.minutes)}>
        <h3>{timer?.minutes}</h3>
        <span>Minutos</span>
      </div>
    </div>
  );
};

export default Timer;
