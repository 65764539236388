import axios from "axios"

export const axiosConfig = {
    baseURL: process.env.REACT_APP_URL_API,
    headers: {
        "Content-Type": "application/json"
    }
}

type GetProps = {
    url: string
}

export const get = async ({url}: GetProps) => {
    const res = await axios.get<any>(url, axiosConfig)

    return res.data
}

type PostProps = {
    url: string
    data: any
}

export const post = async ({url, data}: PostProps) => {
    try {
    const res = await axios.post<any>(url, data, axiosConfig)
    return res.data
    
    } catch (err) {
        if (axios.isAxiosError(err)) {
            console.error('Axios error:', err.response?.data || err.message);
            throw err.response?.data || err;
        }
        throw err
    }
}