import { useState, useEffect, useCallback } from 'react';

type useGoOutEffectHookProps = [boolean, boolean, () => void];

const useGoOutEffectHook = (initialVisible: boolean = true, duration: number = 1000): useGoOutEffectHookProps => {
  const [isVisible, setIsVisible] = useState(initialVisible);
  const [isGoingOut, setIsGoingOut] = useState(false);

    useEffect(() => {
        if(initialVisible) setIsVisible(true)
    }, [initialVisible])

  const triggerGoOutEffect = useCallback(() => {
    setIsGoingOut(true);
    setTimeout(() => {
      setIsVisible(false);
      setIsGoingOut(false);
    }, duration);
  }, [duration]);

  return [isVisible, isGoingOut, triggerGoOutEffect];
};

export default useGoOutEffectHook;