import styles from "./styles.module.css";
import classnames from "classnames";

type AmaountProps = {
  onChange: (value: string) => void;
  initialValue?: number;
  value: number;
};

const Amaount = ({ onChange, initialValue = 0, value = 0 }: AmaountProps) => {
  const onIncrement = () => {
    const newValue = Number(value) + 1;
    onChange(String(newValue));
  };
  const onDecrement = () => {
    if (Number(value) > initialValue) {
      const newValue = Number(value) - 1;
      onChange(String(newValue));
    }
  };
  return (
    <div className={styles.amountContainer}>
      <button
        onClick={onDecrement}
        className={classnames(styles.button, styles.decreaseButton)}
      ></button>
      <input type="text" value={value} readOnly />
      <button
        onClick={onIncrement}
        className={classnames(styles.button, styles.increseButton)}
      ></button>
    </div>
  );
};

export default Amaount;
