import { FC } from "react";
import styles from "./styles.module.css";
import classnames from "classnames";

type PaymentCardProps = {
  Icon: FC;
  label: string;
  name: string;
  onClick: (type: string) => void;
  isSelected?: boolean;
};

const PaymentCard = ({
  Icon,
  label,
  name,
  onClick,
  isSelected = false,
}: PaymentCardProps) => {
  const handleClick = () => {
    onClick(name);
  };

  return (
    <div
      className={classnames(styles.card, { [styles.selected]: isSelected })}
      onClick={handleClick}
    >
      <Icon />
      <span>{label}</span>
    </div>
  );
};

export default PaymentCard;
