import Amaount from "../amount";
import styles from "./styles.module.css";

type AmaountBlockProps = {
  name: string;
  value: number;
  label: string;
  placeholder?: string;
  onChange: (name: string, value: string) => void;
  initialValue?: number;
};

const AmaountBlock = ({
  label,
  placeholder,
  onChange,
  name,
  value,
  initialValue,
}: AmaountBlockProps) => {
  const handleChange = (value: string) => {
    onChange(name, value);
  };

  return (
    <div className={styles.block}>
      <div className={styles.labelContainer}>
        <h5>{label}</h5>
        {placeholder && <span>{placeholder}</span>}
      </div>
      <Amaount
        value={value}
        onChange={handleChange}
        initialValue={initialValue}
      />
    </div>
  );
};

export default AmaountBlock;
