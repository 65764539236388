import classnames from "classnames";
import Button from "../button";
import styles from "./styles.module.css";
import { useContext, useEffect, useState } from "react";
import ExtendedDrawer from "./extendedDrawer";
import { DrawerContext } from "../../contexts/drawer-context";
import ConfirmationModal from "./confirmation-modal";
import useGoOutEffectHook from "../../hooks/useGoOutEffect";
import { useMutation } from "@tanstack/react-query";
import { chooseGifts, FormatedGiftData } from "../../libs/services/gifts";

const Drawer = () => {
  const context = useContext(DrawerContext);

  if (!context) {
    throw new Error("MyContext.Provider is missing");
  }

  const {
    giftList: itens,
    goNextStep,
    canGoToNextStep,
    showConfirmationModal,
    onCloseConfirmationModal,
    currentStep,
    formattedGiftsData,
    refetchList,
    hasErrorOnGiftData,
  } = context;
  const [extended, setExtended] = useState<boolean>(false);
  const mutation = useMutation({
    mutationFn: (newData: FormatedGiftData) => chooseGifts(newData),
  });

  const [isVisible, isGoingOut, triggerGoOutEffect] = useGoOutEffectHook(
    !!itens.length,
    400
  );

  // TODO: When a empty state is made delete this useEffect:
  useEffect(() => {
    if (!itens.length) {
      onToggleExtended();
      triggerGoOutEffect();
    }
  }, [itens, triggerGoOutEffect]);
  // END TODO: --------------------------------------------
  const onToggleExtended = () => {
    setExtended((prev) => !prev);
  };

  const onError = (error: any) => {
    console.log("error", error);
  };

  const onSuccess = () => {
    refetchList();
    triggerGoOutEffect();
    onToggleExtended();
    goNextStep();
  };

  const onNextStep = () => {
    if (currentStep === "gifter_data") {
      const error = hasErrorOnGiftData();
      if (Object.keys(error)?.length < 1) goNextStep();
    } else if (currentStep === "review") {
      mutation.mutate(formattedGiftsData, { onError, onSuccess });
    } else goNextStep();
  };

  const total =
    itens.reduce((acc, item) => acc + item.cost, 0) || Number(0).toFixed(1);
  const itensNumber = itens.length;

  const onClickButton = () => {
    if (!extended) {
      onToggleExtended();
    } else {
      onNextStep();
    }
  };

  return (
    <>
      {isVisible && (
        <>
          <div
            className={classnames(styles.drawerContainer, {
              [styles.showDrawer]: isVisible,
              [styles.hideDrawer]: isGoingOut,
            })}
          >
            <div className={styles.totalContainer}>
              <span>Total</span>
              <h4>
                R$ {total} <span>/{itensNumber} itens</span>
              </h4>
            </div>
            <div>
              <Button
                disabled={!canGoToNextStep && extended}
                label={!extended ? "Ver presentes" : "Continuar"}
                onClick={onClickButton}
              />
            </div>
          </div>
          <ExtendedDrawer isOpen={extended} onClose={onToggleExtended} />
        </>
      )}
      <ConfirmationModal
        isOpen={!!showConfirmationModal}
        type={showConfirmationModal}
        total={total}
        onClose={onCloseConfirmationModal}
      />
    </>
  );
};

export default Drawer;
