import { FC, useEffect } from "react";
import styles from "./styles.module.css";
import classnames from "classnames";

type SnackbarProps = {
  theme?: string;
  label: string;
  Icon: FC;
  duration?: number;
  onClose: () => void;
};

export const SNACKBAR_THEMES = {
  SUCCESS: "success",
};

const Snackbar = ({
  theme = SNACKBAR_THEMES.SUCCESS,
  label,
  Icon,
  duration = 3000,
  onClose,
}: SnackbarProps) => {
  const convertedDelay = duration / 1000 - 0.5;
  const delayAnimation = { animationDelay: `0s, ${convertedDelay}s` };

  useEffect(() => {
    const hideSnackbar = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(hideSnackbar);
  }, [onClose, duration]);

  return (
    <div
      style={delayAnimation}
      className={classnames(styles.snackbar, styles[`theme-${theme}`])}
    >
      <Icon />
      <span>{label}</span>
    </div>
  );
};

export default Snackbar;
