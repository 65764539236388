import classnames from "classnames";
import styles from "./styles.module.css";

type ElementProps = {
  src: string;
  size: { width: number | string; height: number | string };
  position: {
    top?: number | string;
    left?: number | string;
    bottom?: number | string;
    right?: number | string;
    horizontalCenter?: boolean;
  };
};

const Element = ({ src, size, position }: ElementProps) => {
  const { width, height } = size;
  const { top, left, bottom, right } = position;
  const style = {
    backgroundImage: `url(${src})`,
    width: width + "px",
    height: height + "px",
    top: top && top + "px",
    left: left && left + "px",
    bottom: bottom && bottom + "px",
    right: right && right + "px",
  };

  return (
    <div
      style={style}
      className={classnames(styles.element, {
        [styles.horizontalCenter]: position.horizontalCenter,
      })}
    ></div>
  );
};

export default Element;
